import Link from "next/link";
import React, { useEffect, useState } from "react";
import { hero_6_data } from "../../data/hero_6_data";
import Image from "next/image";
import { use } from "chai";

const Hero_6 = ({ transactionsData, userMap }) => {
  const [nftRankedTokens, setNftRankedTokens] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [data, setData] = useState([]);
  const [nftSet, setNftSet] = useState([]);

  useEffect(() => {
    console.log("transactions data: ", transactionsData);
    // Filter unique token_ids and rank them based on price
    const tempTransactions = transactionsData
      .slice(0)
      .filter((transaction) => Number(transaction.price) > 0)
      .sort((a, b) => {
        return Number(b.price) - Number(a.price);
      });
    // remove duplicate tokens ... keep the highest price
    const uniqueTokenIds = [];
    tempTransactions.forEach((transaction) => {
      if (!uniqueTokenIds.includes(transaction.tokenId)) {
        uniqueTokenIds.push(transaction.tokenId);
      }
    });
    // rank the unique tokens based on price
    setNftRankedTokens(uniqueTokenIds);
  }, [transactionsData]);

  useEffect(() => {
    const fetchNftData = async () => {
      nftRankedTokens.slice(0, 9).forEach(async (tokenId) => {
        try {
          const response = await fetch(`/api/nfts/search-nft?pid=${tokenId}`);
          const data = await response.json();
          if (data?.nfts) {
            const obj = {
              id: data.nfts.token_id,
              nftName: data.nfts.nftName,
              nftImage: data.nfts.nftImage,
              creatorWallet: data.nfts.creatorWallet,
            };
            setNftData((prev) => [...prev, obj]);
          }
        } catch (error) {
          console.log("error: ", error);
        }
      });
    };
    fetchNftData();
  }, [nftRankedTokens]);

  useEffect(() => {
    const finalData = [];
    const uniqueTokenIds = [];
    for (let i = 0; i < nftData.length; i++) {
      if (!uniqueTokenIds.includes(nftData[i].id)) {
        uniqueTokenIds.push(nftData[i].id);
        finalData.push(nftData[i]);
      }
    }

    setData([...finalData, ...hero_6_data].slice(0, 9));
  }, [nftData]);

  useEffect(() => {
    console.log("nftData: ", data);
  }, [nftData]);

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative px-6 pb-8 py-24 md:pt-32 bg-transparent">
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {data.slice(0, 4).map((item, index) => {
                const { id, nftName, nftImage, creatorWallet } = item;
                const title = nftName,
                  img = nftImage,
                  authorName = creatorWallet;
                const itemLink = "#";
                return (
                  <article key={index}>
                    <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                        <Link
                          href={`/${itemLink}`}
                          className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                        >
                          <Image
                            width={470}
                            height={470}
                            src={img}
                            alt={title}
                            className="w-full object-cover transition-transform duration-[1600ms] will-change-transform aspect-square group-hover:scale-105"
                          />
                        </Link>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-display text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">
                          {userMap[authorName]?.name
                            ? userMap[authorName]?.name
                            : authorName}
                        </span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            {data.slice(4, 5).map((item, index) => {
              const { id, nftName, nftImage, creatorWallet } = item;
              const title = nftName,
                img = nftImage,
                authorName = creatorWallet;

              const itemLink = "#";
              return (
                <article key={index}>
                  <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                    <figure className="relative">
                      <Link
                        href={`/${itemLink}`}
                        className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                      >
                        <Image
                          width={470}
                          height={470}
                          src={img}
                          alt={title}
                          className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 aspect-square"
                        />
                      </Link>
                    </figure>
                    <div className="pointer-events-none absolute bottom-0 w-full p-5">
                      <h2 className="font-display text-base leading-none text-white xl:text-lg">
                        {title}
                      </h2>
                      <span className="text-2xs text-white">
                        {userMap[authorName]?.name
                          ? userMap[authorName]?.name
                          : authorName}
                      </span>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {data.slice(5, 9).map((item, index) => {
                const { id, nftName, nftImage, creatorWallet } = item;
                const title = nftName,
                  img = nftImage,
                  authorName = creatorWallet;
                const itemLink = "#";
                return (
                  <article key={index}>
                    <div className="relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                        <Link
                          href={`/${itemLink}`}
                          className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20"
                        >
                          <Image
                            width={470}
                            height={470}
                            src={img}
                            alt={title}
                            className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 aspect-square"
                          />
                        </Link>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-display text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">
                          {userMap[authorName]?.name
                            ? userMap[authorName]?.name
                            : authorName}
                        </span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_6;
