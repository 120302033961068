import Image from "next/image";
import Link from "next/link";

const HeroLanding = () => {
  return (
    <section className="hero min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat pb-[20rem]">
      <div className="text-center ">
        <div className="-mt-16 flex items-center justify-center w-full">
          <img
            src="/images/1.png"
            alt="1"
            className="w-[30rem] rounded-full shadow-md"
          />
        </div>
        <div className="-mt-16">
          <h1 className="text-4xl font-semibold pt-4 text-white">
            One Destination
          </h1>
          <h1 className="text-6xl font-bold text-white">
            For NFTs Enthusiasts
          </h1>

          <div className="flex mt-8 justify-center">
            <Link href="/login" className="cursor-pointer">
              <button className="hover:bg-accent text-white transition-all px-4 py-1 rounded-md hover:border-white hover:border-[1px] underline hover:no-underline cursor-pointer">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroLanding;
