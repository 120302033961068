export const coverflow_data = [
  {
    collectionImage: "/images/products/item_13_lg.jpg",
    collectionName: "Etherium NFT Launching Lab",
    creatorImg: "/images/avatars/avatar_18_rounded.gif",
    creatorName: "051_Hart",
    id: "0Etherium NFT Launching Lab",
    creatorUserName: "its_ani",
    items: 0,
  },
  {
    collectionImage: "/images/products/item_16_lg.jpg",
    collectionName: "Oceania \\\\ OVERSEER 017",
    creatorImg: "/images/avatars/avatar_22_rounded.jpg",
    creatorName: "MadeByM1KE",
    id: "1Oceania \\\\ OVERSEER 017",
    creatorUserName: "its_ani",
    items: 0,
  },
  {
    collectionImage: "/images/products/item_12_lg.jpg",
    collectionName: "Light Bars",
    creatorImg: "/images/avatars/avatar_17_rounded.jpg",
    creatorName: "Wow Frens",
    id: "2Light Bars",
    creatorUserName: "its_ani",
    items: 0,
  },
  {
    collectionImage: "/images/products/item_14_lg.jpg",
    collectionName: "Amazing NFT art",
    creatorImg: "/images/avatars/avatar_19_rounded.jpg",
    creatorName: "Lila Spacex",
    id: "3Amazing NFT art",
    creatorUserName: "its_ani",
    items: 0,
  },
  {
    collectionImage: "/images/products/item_15_lg.jpg",
    collectionName: "SwagFox#133",
    creatorImg: "/images/avatars/avatar_20_rounded.jpg",
    creatorName: "Bored Bunny",
    id: "4SwagFox#133",
    creatorUserName: "its_ani",
    items: 0,
  },
  // {
  //   collectionImage: "/images/products/item_13_lg.jpg",
  //   collectionName: "Etherium NFT Launching Lab",
  //   creatorImg: "/images/avatars/avatar_18_rounded.gif",
  //   creatorName: "051_Hart",
  //   id: "5Etherium NFT Launching Lab",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_16_lg.jpg",
  //   collectionName: "Oceania \\\\ OVERSEER 017",
  //   creatorImg: "/images/avatars/avatar_22_rounded.jpg",
  //   creatorName: "MadeByM1KE",
  //   id: "6Oceania \\\\ OVERSEER 017",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_12_lg.jpg",
  //   collectionName: "Light Bars",
  //   creatorImg: "/images/avatars/avatar_17_rounded.jpg",
  //   creatorName: "Wow Frens",
  //   id: "7Light Bars",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_14_lg.jpg",
  //   collectionName: "Amazing NFT art",
  //   creatorImg: "/images/avatars/avatar_19_rounded.jpg",
  //   creatorName: "Lila Spacex",
  //   id: "8Amazing NFT art",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_15_lg.jpg",
  //   collectionName: "SwagFox#133",
  //   creatorImg: "/images/avatars/avatar_20_rounded.jpg",
  //   creatorName: "Bored Bunny",
  //   id: "9SwagFox#133",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_13_lg.jpg",
  //   collectionName: "Etherium NFT Launching Lab",
  //   creatorImg: "/images/avatars/avatar_18_rounded.gif",
  //   creatorName: "051_Hart",
  //   id: "10Etherium NFT Launching Lab",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_16_lg.jpg",
  //   collectionName: "Oceania \\\\ OVERSEER 017",
  //   creatorImg: "/images/avatars/avatar_22_rounded.jpg",
  //   creatorName: "MadeByM1KE",
  //   id: "11Oceania \\\\ OVERSEER 017",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_12_lg.jpg",
  //   collectionName: "Light Bars",
  //   creatorImg: "/images/avatars/avatar_17_rounded.jpg",
  //   creatorName: "Wow Frens",
  //   id: "12Light Bars",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_14_lg.jpg",
  //   collectionName: "Amazing NFT art",
  //   creatorImg: "/images/avatars/avatar_19_rounded.jpg",
  //   creatorName: "Lila Spacex",
  //   id: "13Amazing NFT art",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
  // {
  //   collectionImage: "/images/products/item_15_lg.jpg",
  //   collectionName: "SwagFox#133",
  //   creatorImg: "/images/avatars/avatar_20_rounded.jpg",
  //   creatorName: "Bored Bunny",
  //   id: "14SwagFox#133",
  //   creatorUserName: "its_ani",
  //   items: 0,
  // },
];

export const hero_5_data = [
  {
    collectionImage: "/images/products/item_main1.jpg",
    collectionName: "Etherium NFT Launching Lab",
    creatorImg: "/images/avatars/avatar_18_rounded.gif",
    creatorName: "051_Hart",
    id: "0Etherium NFT Launching Lab",
  },
  {
    collectionImage: "/images/products/item_main2.jpg",
    collectionName: "Oceania \\\\ OVERSEER 017",
    creatorImg: "/images/avatars/avatar_22_rounded.jpg",
    creatorName: "MadeByM1KE",
    id: "1Oceania \\\\ OVERSEER 017",

    subItem: [
      {
        collectionImage: "/images/products/item_main3.jpg",
        collectionName: "Light Bars",
        creatorImage: "/images/avatars/avatar_17_rounded.jpg",
        creatorName: "Wow Frens",
        id: "2Light Bars",
      },
    ],
  },
];
