import React, { useEffect, useState } from "react";
// import { NewseLatter } from "../component";
import Meta from "../Meta";
import Hero_6 from "../hero/hero_6";
import CoverflowCarousel from "../carousel/coverflowCarousel";
import Top_collection_hero from "../collectrions/top_collection_hero";
import Hero_Product from "../hero/hero_product";
import Hero_13 from "../hero/hero_13";
import Footer from "../footer";
import Image from "next/image";
import { HeadLine } from "../component";
import Process from "../blog/process";

const Home_1 = ({ user }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [uniqueCollections, setUniqueCollections] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userMap, setUserMap] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch("/api/users/user-details"); // Adjust the API endpoint as needed
        if (!response.ok) {
          throw new Error("Failed to fetch wallet addresses");
        }
        const data = await response.json();
        setUserDetails(data.userDetails);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchCollectionTokens = async () => {
      try {
        const response = await fetch(
          "/api/nfts/search-nft?getUniqueCollections=true"
        ); // Adjust the API endpoint as needed
        if (!response.ok) {
          throw new Error("Failed to fetch collection tokens");
        }
        const data = await response.json();
        setUniqueCollections(data.nfts);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchCollections = async () => {
      try {
        const response = await fetch("/api/collections/get-collections"); // Adjust the API endpoint as needed
        if (!response.ok) {
          throw new Error("Failed to fetch collections");
        }
        const data = await response.json();
        setCollectionsData(data.collections);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          "/api/transactions/fetch-activity?eventName=transfer"
        ); // Adjust the API endpoint as needed
        if (!response.ok) {
          throw new Error("Failed to fetch collections");
        }
        const data = await response.json();
        setTransactionsData(data.transactions);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchUserDetails();
    fetchCollectionTokens();
    fetchCollections();
    fetchTransactions();
  }, []);

  useEffect(() => {
    const map = userDetails.reduce((acc, u) => {
      acc[u.walletAddress] = {
        username: u.username,
        name: u.name,
        profilePhotoUrl: u.profilePhotoUrl,
      };
      return acc;
    }, {});
    setUserMap(map);
  }, [userDetails]);

  useEffect(() => {
    if (uniqueCollections.length > 0 && collectionsData.length > 0) {
      const arr = [];
      let i = 0;
      for (const collection of uniqueCollections) {
        const collectionData = collectionsData.find(
          (data) =>
            data.collection_name === collection.collectionName &&
            data.collection_owner_wallet === collection.creatorWallet
        );
        const collectionTransactions = transactionsData.filter((transaction) =>
          collection.tokens_arr.includes(transaction.tokenId)
        );
        const volume = collectionTransactions
          .reduce((acc, transaction) => {
            return acc + Number(transaction.price);
          }, 0)
          .toFixed(2);
        const items = collection.itemsCount;
        const isVerified = collectionData?.isVerified;
        const obj = {
          id: i,
          collectionImage: collectionData.collection_image,
          collectionName: collectionData.collection_name,
          creatorUserName:
            userMap[collectionData.collection_owner_wallet]?.username,
          creatorName: userMap[collectionData.collection_owner_wallet]?.name,
          creatorImage:
            userMap[collectionData.collection_owner_wallet]?.profilePhotoUrl,
          volume,
          items,
          icon: isVerified,
        };
        arr.push(obj);
        i++;
      }
      arr.sort((a, b) => b.volume - a.volume);
      setData(arr);
    }
  }, [uniqueCollections, collectionsData, transactionsData]);

  return (
    <main className="bg-transparent relative">
      <Meta title="Home" />
      {/* <Image
        src="/giphy.webp"
        alt="A gif of a person dancing"
        width={500}
        height={500}
        className="fixed top-0 left-0 w-full h-full z-[0] object-cover"
      /> */}

      {/* <iframe
        src="https://giphy.com/embed/LpGTkDbFtLQt5J6LGL"
        width="100%"
        height="100%"
        style={{ position: "fixed" }}
        className="giphy-embed"
        allowFullScreen
      ></iframe> */}
      <Hero_Product transactionsData={transactionsData} userMap={userMap} />
      <Top_collection_hero data={data} />
      {/*<Bids />*/}

      {/* <Hero_5 /> */}
      <div className="flex justify-center items-center font-bold text-3xl mb-2">
        <HeadLine text="Notable Collections" classes="inline text-white" />
      </div>
      <CoverflowCarousel data={data} />

      <div className="flex justify-center items-center font-bold text-3xl mt-7">
        <HeadLine text="Expensive Sale" classes="inline text-white" />
      </div>
      <Hero_6 transactionsData={transactionsData} userMap={userMap} />
      {/* <h1 className="my-16 mb-24 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <HeadLine text="Create and Sell Nfts" classes="inline text-white" />
          </h1> */}

      {/* <Hero_13 /> */}
      <Process />
      <Footer />

      {/* <NewseLatter /> */}
    </main>
  );
};

export default Home_1;
