/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState, useRef } from "react";
import Meta from "../Meta";
import Hero_landing from "../hero/hero_landing";
import Benifits from "../dao/Benifits";
import Testimonial from "../testimonial/Testimonial";
import Process from "../blog/process";
import Participate from "../dao/Participate";
import NewseLatter2 from "../dao/newseLatter2";
import Services from "../services/services";
import Link from "next/link";
// import Alert from "../alert";

const Landing = () => {
  return (
    <div className="bg-[black]">
      <Meta title="Explore Collection || Xhibiter | NFT Marketplace Next.js Template" />
      {/* <Alert /> // demo project alert  */}
      <Hero_landing />
      {/*<Benifits />*/}
      {/* <Services />
      <Testimonial />
      <Process /> */}
      {/*      <Participate />

       <div style={{ backgroundImage: `url('/images/gradient_dark.jpg')` }}>
        <NewseLatter2 />
      </div>

  
       */}

      {/* <div className="flex justify-center items-center h-screen bg-[black]  text-white flex-col gap-8">
        <div className="text-6xl font-bold">
          <div className="flex justify-center items-center">
            <h3>Dont&apos; wait,</h3>
          </div>
          <h3>Get started today!</h3>
        </div>
        <Link href="/login">
          <button className="px-52 border-accent border-[2px] rounded-lg text-accent  py-4 bg-white text-xl font-bold hover:opacity-95 hover:transition-all">
            Join Now
          </button>
        </Link>
      </div> */}
    </div>
  );
};

export default Landing;
// {
/*
      <div className="form-wrapper min-h-screen flex justify-center items-center bg-cover bg-center">
        <div className="form-container backdrop-blur-lg w-screen h-screen lg:w-[80vw] lg:h-[90vh] p-8 lg:p-20 rounded-2xl  text-[#1A2421] bg-gradient-to-b from-white/60 to-white/30  border-[1px] border-solid border-white border-opacity-30 shadow-black/70 shadow-2xl flex items-center justify-center flex-col">
          <div className="max-w-md flex items-center justify-center flex-col">
            <h1 className="text-3xl  lg:text-5xl font-extrabold mb-8">
              Don&apos;t Wait. Become Our Member Today.
            </h1>
            <button className=" hover:bg-accent transition-all text-white px-4 py-1 rounded-md hover:border-white hover:border-[1px] underline hover:no-underline">
              Get started
            </button>
          </div>
        </div>
      </div> */
// }
