import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import Image from "next/image";

import { hero_product_swiper_data } from "../../data/hero_product_swiper_data";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Hero_Product = ({ transactionsData, userMap }) => {
  const [activeThumb, setActiveThumb] = useState(null);
  const [nftRankedTokens, setNftRankedTokens] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("transactions data: ", transactionsData);
    // Filter unique token_ids and rank them based on price
    const tempTransactions = transactionsData
      .slice(0)
      .filter((transaction) => Number(transaction.price) > 0)
      .sort((a, b) => {
        return Number(b.price) - Number(a.price);
      });
    // remove duplicate tokens ... keep the highest price
    const uniqueTokenIds = [];
    tempTransactions.forEach((transaction) => {
      if (!uniqueTokenIds.includes(transaction.tokenId)) {
        uniqueTokenIds.push(transaction.tokenId);
      }
    });
    // rank the unique tokens based on price
    setNftRankedTokens(uniqueTokenIds);
  }, [transactionsData]);

  useEffect(() => {
    const fetchNftData = async () => {
      nftRankedTokens.slice(0, 9).forEach(async (tokenId) => {
        try {
          const response = await fetch(`/api/nfts/search-nft?pid=${tokenId}`);
          const data = await response.json();
          if (data?.nfts) {
            const obj = {
              id: data.nfts.token_id,
              nftName: data.nfts.nftName,
              nftImage: data.nfts.nftImage,
              creatorWallet: data.nfts.creatorWallet,
            };
            setNftData((prev) => [...prev, obj]);
          }
        } catch (error) {
          console.log("error: ", error);
        }
      });
    };
    fetchNftData();
  }, [nftRankedTokens]);

  useEffect(() => {
    const finalData = [];
    const uniqueTokenIds = [];
    for (let i = 0; i < nftData.length; i++) {
      if (!uniqueTokenIds.includes(nftData[i].id)) {
        uniqueTokenIds.push(nftData[i].id);
        const obj = {
          image: nftData[i].nftImage,
          id: nftData[i].id,
          title: nftData[i].nftName,
          name: userMap[nftData[i].creatorWallet]?.name,
          titleLink: `/user/${userMap[nftData[i].creatorWallet]?.username}`,
          userLink: `/user/${userMap[nftData[i].creatorWallet]?.username}`,
        };
        finalData.push(obj);
      }
    }

    setData([...finalData, ...hero_product_swiper_data].slice(0, 3));
  }, [nftData]);

  useEffect(() => {
    console.log("nftData: ", data);
  }, [data]);
  return (
    <>
      {/* <!-- Hero Slider --> */}
      <section className="relative h-screen mt-14 lg:mt-10">
        <Swiper
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="full-slider h-screen"
        >
          {data?.map((item) => {
            const { image, id, title, name, titleLink, userLink } = item;

            return (
              <SwiperSlide key={id} className="px-24 py-16 rounded-lg">
                <div className="swiper-slide after:bg-jacarta-900/60 after:rounded-2xl after:absolute after:inset-0">
                  <div className="container relative z-10 h-full pt-40 rounded-2xl">
                    <h2 className="font-display text-2xl font-semibold text-white">
                      <Link href={titleLink}>{title}</Link>
                    </h2>
                    <Link
                      href={userLink}
                      className="text-2xs font-medium text-white"
                    >
                      {name}
                    </Link>
                  </div>

                  <Image
                    width={1519}
                    height={722}
                    src={image}
                    className="absolute inset-0 h-full w-full object-cover rounded-2xl"
                    alt={title}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        {/* <!-- Thumbs --> */}
        <div className="absolute inset-x-0 bottom-12">
          <div className="container">
            {/* <Thumbs_carousel /> */}
            <Swiper
              modules={[FreeMode, Navigation, Thumbs]}
              onSwiper={setActiveThumb}
              loop={false}
              spaceBetween={10}
              slidesPerView="auto"
              breakpoints={{
                100: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
              }}
              className=" full-slider-thumbs swiper-initialized swiper-horizontal swiper-pointer-events swiper-thumbs"
            >
              {data?.map((item) => {
                const { image, id } = item;
                return (
                  <SwiperSlide
                    className="swiper-slide cursor-pointer rounded p-5 swiper-slide-duplicate swiper-slide-duplicate-next "
                    key={id}
                  >
                    <Image
                      width={343}
                      height={188}
                      src={image}
                      className="w-full h-full object-cover aspect-video rounded-lg"
                      alt="thumb 1"
                    />
                    <div className="carousel-progress relative -bottom-5 z-10 -ml-5 -mr-5 h-0.5 bg-white/20 ">
                      <div className="progress bg-accent absolute h-0.5 w-0"></div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero_Product;
